import styled from 'styled-components/macro';
import { Box, Heading,Button,Paragraph } from '~styled';
import {transparentize} from "polished"

const propToColor = (arg = "#1787FC" ) => transparentize(0.70, arg);

const Service = styled(Box)`
    padding-top: 60px;
    padding-bottom: 60px;
    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (min-width:992px) {
        padding-top: 125px;
        padding-bottom: 130px;
    }
`


Service.Box = styled(Box)`

`
Service.Terms = styled(Heading)`
    font-size: 32px;
    text-align: justify;
    font-weight: 400;
    color: #2d3136;
    margin-top: 30px;
    letter-spacing: normal;
    line-height: 1.4423;
    margin-bottom: 17px;
    margin-left: 20px;
    @media (min-width:575px) {
      font-size: 25px;
    }
    @media (min-width:768px) {
      font-size: 30px;
    }
    @media (min-width:992px) {
      font-size: 40px;
    }
` 
Service.Points = styled(Paragraph)`
    font-size: 17px;
    text-align: justify;
    font-weight: 400;
    color: #818992;
    letter-spacing: normal;
    line-height: 1.61904;
    @media (min-width:575px) {
    font-size: 14px;
    }
    @media (min-width:992px) {
    font-size: 17px;
    }
` 
Service.Icon = styled(Paragraph)`
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 24px;
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    color:${props => props.iconDark? "#000" : "#fff" };
    background:${props => props.backgroundColor? props.backgroundColor: "#5034fc" };
    box-shadow:  ${props => "-12px 12px 50px " + propToColor(props.backgroundColor)};
`
Service.Adjust = styled(Paragraph)`
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 46px;
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    color:${props => props.iconDark? "#000" : "#fff" };
    background:${props => props.backgroundColor? props.backgroundColor: "#5034fc" };
    box-shadow:  ${props => "-12px 12px 50px " + propToColor(props.backgroundColor)};
`
Service.Affiliates = styled(Paragraph)
`
font-size: 16px;
font-weight: 400;
text-align: center;
margin-top: 30px;
color: #2d3136;
letter-spacing: normal;
line-height: 1.777777;
@media (min-width: 992px){
    font-size: 17px;
}
`
Service.Button = styled(Button)`
    transition:.4s;
    margin-bottom: 40px;
    margin-top: 30px;
    border-color:${props => props.backgroundColor? props.backgroundColor: "#5034fc" };
    background:${props => props.backgroundColor? props.backgroundColor: "#5338FC" };
    box-shadow:  ${props => "0 4px 4px " + propToColor(props.backgroundColor)};
  &:hover{
    transform: translateY(-5px);
    border-color:${props => props.hoverBackgroundColor? props.hoverBackgroundColor: "#442cd6" };
    background:${props => props.hoverBackgroundColor? props.hoverBackgroundColor: "#442cd6" };
    box-shadow:  ${props => "0 4px 4px " + propToColor(props.hoverBackgroundColor)};
  }
`
export default Service;
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
//import ServiceData  from "~data/startup/service"
import Service from './style'
import { Link } from "gatsby";
import SectionTitle from './Components/SectionTitle'
import image1 from "../../../assets/image/marketing/Easily-Managable.svg";
import image2 from "../../../assets/image/marketing/Track-Your-Customers.svg";
import image3 from "../../../assets/image/marketing/Grow-with-weconnect.svg";
import  ServicesCard from "./Components/Card"
export default function ServicesSectionOne({backgroundColor,colClasses,...rest}){
return(
<Service backgroundColor="#f3f4f6" {...rest}>
  <Container>
    
    <Row className="justify-content-center aos-init aos-animate" data-aos="fade-up" data-aos-duration={500} data-aos-delay={500} data-aos-once="true">
      {/* Single Services */}
      
              <Col xs="h2" className="col-xl-3 col-lg-4 col-sm-6 col-xs-8" >
                <ServicesCard
                  title="Easily Managable"
                  text="A cloud-based, competitively priced product that will catch immediate attention of your prospects. Target anyone that uses LinkedIn for prospecting. Huge audience!"
                  image={image1}
                  iconBackground="#F3F4F6"
                />
              </Col>
              <Col xs="h2" className="col-xl-3 col-lg-4 col-sm-6 col-xs-8" >
                <ServicesCard
                  title="Track Your Customers"
                  text="Generate customized links, share with your audience and track sales from your private dashboard. Get notified when a lead signs up and more. Commissions automatically verified deposited in your account."
                  image={image2}
                  iconBackground="#F3F4F6"
                />
              </Col>
              <Col xs="h2" className="col-xl-3 col-lg-4 col-sm-6 col-xs-8" >
                <ServicesCard
                  title="Grow with We-Connect"
                  text="Anyone can make money online as We-Connect Affiliates - Marketers, Bloggers, Content Writers, Freelancers, LinkedIn Coaches, Twitter & Facebook promoters, Social media superstars, email marketers..."
                  image={image3}
                  iconBackground="#F3F4F6"
                />
              </Col>
              
            
      {/*/ .Single Services */}
    </Row>
    <Row >
      <Col className="col-lg-12 col-sm-12 col-xs-12">
        <Service.Terms>We-Connect Affiliate Terms</Service.Terms>
      <Row>
      <Col className="col-lg-1 col-sm-1 col-xs-1">

        <Service.Icon>1</Service.Icon>
        <Service.Icon>2</Service.Icon>
        <Service.Icon>3</Service.Icon>
        <Service.Icon>4</Service.Icon>
        <Service.Icon>5</Service.Icon>
        <Service.Icon>6</Service.Icon>
        <Service.Adjust>7</Service.Adjust>
        <Service.Icon>8</Service.Icon>
        <Service.Icon>9</Service.Icon>
        <Service.Icon>10</Service.Icon>
        <Service.Icon>11</Service.Icon>
      </Col>
      <Col className="col-lg-11 col-sm-11 col-xs-11">
        <Service.Points>You are currently using or have used We-Connect for at least 14 days.</Service.Points>
        <Service.Points>You will genuinely promote We-Connect to your audience and network for our mutual benefit.</Service.Points>
        <Service.Points>Self-referrals are not allowed.</Service.Points>
        <Service.Points>Conversions will be counted once your referral becomes a paid customer. Free trial users are not counted as conversions.</Service.Points>
        <Service.Points>Conversions are tracked through your affiliate link provided by our affiliate tracking software, First Promoter.</Service.Points>
        <Service.Points>Payouts will be made on the 15th of each month for the previous month’s earnings.</Service.Points>
        <Service.Points>We offer a 60-day cookie life. In other words, if they purchase over the next 60 days by clicking on your link you will be counted as the affiliate for that purchase.</Service.Points>
        <Service.Points>You need to earn $50 minimum before being paid out.</Service.Points>
        <Service.Points>No activity for 3 months may cause your account to be terminated.</Service.Points>
        <Service.Points>We will review all disputes on a good faith basis and We-Connect’s decision will be final in case of any disputes.</Service.Points>
        <Service.Points>Any misuse or violation of affiliate terms will get your account terminated.</Service.Points>
      </Col> 
      </Row> 
        </Col> 
    </Row>
    
    <Service.Affiliates>For any questions about our affiliate program, please contact us at <a href="mailto: affiliates@we-connect.io">affiliates@we-connect.io</a></Service.Affiliates>
    <Row className="row justify-content-center text-center">
      <Col className="col-lg-12 col-sm-12 col-xs-12">
      
      <Link to="//affiliates.we-connect.io" target="_blank"><Service.Button textColor="#fff" hoverTextColor="#fff" backgroundColor={backgroundColor}  hoverBackgroundColor={backgroundColor} sizeX="180px" sizeY="56px" rounded="true" >Apply now</Service.Button></Link>
      
    </Col>
    </Row>

  </Container>
</Service>

)
}